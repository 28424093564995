<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('MENU.agents_management') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">
          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.first_name') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.first_name" class="form-control"
                     :class="validation && validation.first_name ? 'is-invalid' : ''"
                     :placeholder="$t('agents.first_name')"
              />
              <span v-if="validation && validation.first_name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.first_name[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.middle_name') }}</label>
              <input type="text" v-model="data.middle_name" class="form-control"
                     :class="validation && validation.middle_name ? 'is-invalid' : ''"
                     :placeholder="$t('agents.middle_name')"
              />
              <span v-if="validation && validation.middle_name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.middle_name[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.last_name') }}<span class="text-danger">*</span></label>
              <input type="text" class="form-control" :class="validation && validation.last_name ? 'is-invalid' : ''"
                     v-model="data.last_name"
                     :placeholder="$t('agents.last_name')"
              />
              <span
                  v-if="validation && validation.last_name"
                  class="fv-plugins-message-container invalid-feedback"
              >
                  {{ validation.last_name[0] }}
                </span>
            </div>


            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.email') }}</label>
              <input
                  type="email"
                  class="form-control"
                  :class="validation && validation.email ? 'is-invalid' : ''"
                  v-model="data.email"
                  :placeholder="$t('agents.email')"
              />

              <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                {{ validation.email[0] }}
              </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{$t('agents.user_agent')}}</label>
                <div class="input-group">
                  <multiselect
                    :class="validation && validation.user_agent_id ? 'is-invalid' : ''"
                    v-model="user"
                    :placeholder="$t('agents.user_agent')"
                    label="name"
                    track-by="id"
                    :options="users"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false">
                  </multiselect>
                </div>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.mobile') }}</label>
              <div class="input-group">
                <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.mobile[0] }}
                  </span>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.phone') }}</label>
              <div class="input-group">
                <vue-tel-input v-model="data.phone" :class="validation && validation.phone ? 'is-invalid' : ''"></vue-tel-input>
                <span v-if="validation && validation.phone" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.phone[0] }}
                  </span>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.nationality') }}</label>
              <div class="input-group">
                <input type="text" class="form-control"
                       :class="validation && validation.nationality ? 'is-invalid' : ''"
                       v-model="data.nationality" :placeholder="$t('agents.nationality')"/>
                <span v-if="validation && validation.nationality" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.nationality[0] }}
                  </span>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.default_language') }}</label>
              <select name="" id="language_id" v-model="data.language_id" class="custom-select"
                      :class="validation && validation.language_id ? 'is-invalid' : ''">
                <option v-for="row in supported_language_list" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select>
              <span v-if="validation && validation.language_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.language_id[0] }}
                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.supported_language') }}</label>
              <div class="input-group">
                <multiselect
                    :class="validation && validation.supported_language ? 'is-invalid' : ''"
                    v-model="supported_language_ids"
                    :placeholder="$t('agents.supported_language')"
                    label="name"
                    track-by="id"
                    :options="supported_language_list"
                    :multiple="true"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    @input="setSupportedLanguageIds"
                >
                </multiselect>
                <span v-if="validation && validation.supported_language"
                      class="fv-plugins-message-container invalid-feedback">
                    {{ validation.supported_language[0] }}
                </span>
              </div>
            </div>


            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.supported_country') }}</label>
              <div class="input-group">
                <multiselect
                    :class="validation && validation.supported_country ? 'is-invalid' : ''"
                    v-model="country"
                    :placeholder="$t('agents.supported_country')"
                    label="name"
                    track-by="id"
                    :options="countries"
                    :multiple="true"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    @search-change="getCountries($event)"
                    :internal-search="false"
                    @input="setCountryIds"
                >
                </multiselect>
                <span v-if="validation && validation.supported_country"
                      class="fv-plugins-message-container invalid-feedback">
                    {{ validation.supported_country[0] }}
                </span>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.supported_filed') }}</label>
              <div class="input-group">
                <multiselect
                    :class="validation && validation.supported_filed ? 'is-invalid' : ''"
                    v-model="supported_filed_ids"
                    :placeholder="$t('agents.supported_filed')"
                    label="name"
                    track-by="id"
                    :options="supported_filed_list"
                    :multiple="true"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    @input="setSupportedFiledIds"
                >
                </multiselect>
                <span v-if="validation && validation.supported_filed"
                      class="fv-plugins-message-container invalid-feedback">
                    {{ validation.supported_filed[0] }}
                </span>
              </div>
            </div>


            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.currency') }}</label>
              <select name="" id="currency_id" v-model="data.currency_id" class="custom-select"
                      :class="validation && validation.currency_id ? 'is-invalid' : ''">
                <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select>
              <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.currency_id[0] }}
                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.discount') }}</label>
              <input type="number" v-model="data.discount" class="form-control" :class="validation && validation.discount ? 'is-invalid' : ''" :placeholder="$t('agents.discount')" />
              <span v-if="validation && validation.discount" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.discount[0] }}
                </span>
            </div>

            <div class="col-md-6 mb-5">
              <div class="form-group">
                <label>{{ $t('agents.is_general_country') }}:</label>
                <b-form-checkbox size="lg" v-model="data.is_general_country" name="check-button" switch></b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6 mb-5">
              <div class="form-group">
                <label>{{ $t('agents.is_general_field') }}:</label>
                <b-form-checkbox size="lg" v-model="data.is_general_field" name="check-button" switch></b-form-checkbox>
              </div>
            </div>
            <div class="col-md-6 mb-5">
              <div class="form-group">
                <label>{{ $t('status') }}:</label>
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch></b-form-checkbox>
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button
                  type="reset"
                  class="btn btn-primary mr-2"
                  @click="save()"
              >
                {{ $t('save') }}
              </button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-user",
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRoute: 'agents/agents',
      data: {
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        mobile: null,
        phone: null,
        nationality: null,
        language_id: null,
        user_agent_id: null,
        supported_language: [],
        supported_country: [],
        discount : null,
        supported_field: [],
        is_general_country: false,
        is_general_field: false,
        currency_id: null,
        is_active: true,
      },

      isEditing: false,
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      validation: null,
      country: [],
      users: [],
      user: null,

      supported_language_ids: [],
      supported_filed_ids: [],

      supported_language_list: [
        // {id: 'ar', title: this.$t('arabic')},
        // {id: 'en', title: this.$t('english')},
      ],
      supported_filed_list: [],
      currencies: [],
      countries: [],

    };
  },
   watch: {
    "user": function (val) {
        if (val) {
            this.data.user_agent_id = val.id;
        }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'agents.index'});
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    update() {
      ApiService.put(this.mainRoute + `/${this.$route.params.id}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'agents.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(this.mainRoute + `/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.first_name = response.data.data.first_name;
        this.data.middle_name = response.data.data.middle_name;
        this.data.last_name = response.data.data.last_name;
        this.data.email = response.data.data.email;
        this.data.mobile = response.data.data.mobile;
        this.data.phone = response.data.data.phone;
        this.data.nationality = response.data.data.nationality;
        this.data.language_id = response.data.data.language_id;
        this.data.supported_language = response.data.data.supported_language;
        this.data.supported_country = response.data.data.supported_country;
        this.data.supported_field = response.data.data.supported_field;
        this.data.discount = response.data.data.discount;
        this.data.is_general_country = response.data.data.is_general_country;
        this.data.is_general_field = response.data.data.is_general_field;
        this.data.currency_id = response.data.data.currency_id;
        this.data.user_agent_id = response.data.data.user_agent_id;
        this.data.is_active = response.data.data.is_active;
        this.user = response.data.data.user_agent;
        this.country = response.data.data.country_objs;
        this.supported_language_ids = this.supported_language_list.filter(row=>response.data.data.supported_language.includes(row.id));
        this.supported_filed_ids = this.supported_filed_list.filter(row=>response.data.data.supported_field.includes(row.id));
      });
    },

    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(this.mainRouteDependency + "/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },
    
    getUsers() {
        ApiService.get(`${this.mainRouteDependency}/users`).then((response) => {
          this.users = response.data.data;
        });
    },
    async getSupportedFiledList() {
      await ApiService.get(this.mainRouteDependency + "/business-fields").then((response) => {
        this.supported_filed_list = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    async getLanguages() {
      await ApiService.get(this.mainRouteDependency + "/languages").then((response) => {
        this.supported_language_list = response.data.data;
      });
    },

    setCountryIds(event) {
      this.data.supported_country = [];
      event.forEach((row) => {
        this.data.supported_country.push(row.id);
      });
    },
    setSupportedFiledIds(event) {
      this.data.supported_field = [];
      event.forEach((row) => {
        this.data.supported_field.push(row.id);
      });
    },
    setSupportedLanguageIds(event) {
      this.data.supported_language = [];
      event.forEach((row) => {
        this.data.supported_language.push(row.id);
      });
    },
  },


  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.agents_management"),
      route: '/agents/agents'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    this.getUsers();
    this.getCurrencies();
    let promise = this.getSupportedFiledList();
    let promise1 = this.getLanguages();
    if (this.idEditing) {
      Promise.all([promise, promise1]).then(() => {
        this.getData();

      })
    }
  },
};
</script>


